import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import Cta from "../components/cta"

import "../styles/object/component/typography.scss"
import CardBusiness from "../components/cardBusiness";

export default () => (
    <Layout title="Business">
        <Title top="BUSINESS" topSub="事業内容"/>
        <main className="l-main">
            <section className="business">
                <div className="c-heading">
                    <h4 className="c-heading_main">PRODUCTION</h4>
                    <h5 className="c-heading_sub">製作実績</h5>
                </div>
                <div className="container">
                    <CardBusiness
                        imageName="top03.jpg"
                        imageAlt="株価情報ツール"
                        title="株価情報ツール"
                        company="株式会社トレードワークス"
                        tags={["フロントエンド", "金融", "国内株", "ReactJS"]}
                        content="証券会社向けの国内株式情報システム。主にReactJSを使用したフロントエンドWEBシステムを開発。"
                    />
                    <CardBusiness
                        imageName="top04.jpg"
                        imageAlt="先物・FX取引ツール"
                        title="先物・FX取引ツール"
                        company="株式会社トレードワークス"
                        tags={["フロントエンド", "金融", "先物", "オプション", "FX", "ReactJS"]}
                        content="証券会社向けの先物OP・FX・CFD取引システム。主にReactJSを使用したフロントエンドWEBシステムを開発。"
                    />
                    <CardBusiness
                        imageName="business03.jpg"
                        imageAlt="海外株取引ツール"
                        title="海外株取引ツール"
                        company="株式会社トレードワークス"
                        tags={["フロントエンド", "金融", "日本株", "海外株", "デスクトップアプリ"]}
                        content="国内株・海外株を中心とした取引システム。主にReactJSとElectronを使用し、クロスプラットフォームなデスクトップアプリを開発。"
                    />
                    {/*<CardBusiness*/}
                    {/*    imageName="business03.jpg"*/}
                    {/*    imageAlt="海外株取引ツール"*/}
                    {/*    title="海外株取引ツール"*/}
                    {/*    company="株式会社トレードワークス"*/}
                    {/*    tags={["フロントエンド", "金融", "国内株", "先物・オプション", "投資信託"]}*/}
                    {/*    content="国内株・海外株を中心とした取引システム。主にReactJSとElectronを使用し、クロスプラットフォームなデスクトップアプリを開発"*/}
                    {/*/>*/}
                </div>
            </section>
            <Cta/>
        </main>
    </Layout>
)
